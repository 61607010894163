import { twMerge } from 'tailwind-merge';
import ToggleButton, {
  type ToggleButtonProps,
} from '@mui/material/ToggleButton';
import { classes, styles } from './utils';

export type ToggleOptionProps = ToggleButtonProps;

export default function ToggleOption({
  className = '',
  ...props
}: ToggleOptionProps) {
  return (
    <ToggleButton
      sx={styles.toggleButton}
      className={twMerge(classes.toggleButton, className)}
      {...props}
    />
  );
}
