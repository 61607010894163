import { twMerge } from 'tailwind-merge';
import ToggleButtonGroup, {
  type ToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';
import { classes } from './utils';

export { default as ToggleOption } from './ToggleOption';

export type ToggleBlockProps<T, N extends string> = Omit<
  ToggleButtonGroupProps,
  'onChange'
> & {
  name: string;
  className?: string;
  exclusive?: boolean;
  onChange: (value: Record<N, T>) => void;
};

export default function ToggleBlock<T, N extends string>({
  name,
  className = '',
  exclusive = true,
  onChange,
  ...props
}: ToggleBlockProps<T, N>) {
  const handleChange = (e: any, value: T | null) => {
    if (value === null) return; // Selecting the current value
    return onChange({ [name]: value } as Record<N, T>);
  };

  return (
    <ToggleButtonGroup
      exclusive={exclusive}
      className={twMerge(classes.wrapper, className)}
      onChange={handleChange}
      {...props}
    />
  );
}
