import { theme } from '@/utils/theme';

export const classes = {
  toggleButton:
    'py-2 mx-1.5 my-1 border-none normal-case transition-colors text-grey-7 text-sm',
};

export const styles = {
  toggleButton: {
    '&': {
      border: 'none !important',
    },
    '&.MuiToggleButtonGroup-grouped': {
      '&:not(:last-of-type), &:not(:first-of-type)': {
        // @ts-ignore
        borderTopRightRadius: theme.shape.rounded.lg,
        // @ts-ignore
        borderBottomRightRadius: theme.shape.rounded.lg,
        // @ts-ignore
        borderTopLeftRadius: theme.shape.rounded.lg,
        // @ts-ignore
        borderBottomLeftRadius: theme.shape.rounded.lg,
      },
    },
    '&:not(.Mui-selected)': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette['grey-8'].main,
      backgroundColor: theme.palette['grey-2'].main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:hover': {
      color: theme.palette['grey-7'].main,
      backgroundColor: theme.palette['grey-2'].main,
    },
  },
};
