import Card from '@/components/Card';
import { type FileTypeID } from '@witmetrics/api-client';
import ToggleBlock, { ToggleOption } from '@/components/ToggleBlock';
import { useFilesByStepID } from '@/store/useStore';
import CheckBoxIcon from '@/icons/CheckBoxIcon';
import UncheckBoxIcon from '@/icons/UncheckBoxIcon';
import { classes, getFileTypes } from './utils';

export type FileTypesToggleProps = {
  stepID: number;
  visibleFileTypeIDs: FileTypeID[];
  onChange: (fileTypeIDs: FileTypeID[]) => void;
};

export default function FileTypesToggle({
  stepID,
  visibleFileTypeIDs,
  onChange,
}: FileTypesToggleProps) {
  const files = useFilesByStepID(stepID);
  const fileTypes = getFileTypes(files);

  if (files.length === 0) return null;

  return (
    <Card className={classes.card}>
      {files.length === 0 ? (
        <div className={classes.message}>No files found.</div>
      ) : (
        <ToggleBlock<FileTypeID[], 'fileTypeIDs'>
          exclusive={false}
          className={classes.toggleBlock}
          name="fileTypeIDs"
          value={visibleFileTypeIDs}
          onChange={(update) => onChange(update.fileTypeIDs)}>
          {fileTypes.map((f) => (
            <ToggleOption key={f.id} value={f.id}>
              {visibleFileTypeIDs.includes(f.id) ? (
                <CheckBoxIcon />
              ) : (
                <UncheckBoxIcon />
              )}
              <span className={classes.label}>{f.label}</span>
            </ToggleOption>
          ))}
        </ToggleBlock>
      )}
    </Card>
  );
}
