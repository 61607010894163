import { FILE_TYPES, type UndecoratedFile } from '@witmetrics/api-client';

export const classes = {
  card: 'px-2 py-1 ml-2',
  message: 'px-4 text-lg text-grey-7 py-8 text-center',
  toggleBlock: 'border-none',
  label: 'ml-2',
};

export function getFileTypes(files: UndecoratedFile[]) {
  const fileTypeIDs = files.map(getFileTypeID);
  // Maintains consistent order of fileTypes
  return Object.values(FILE_TYPES).filter((f) => fileTypeIDs.includes(f.id));
}

function getFileTypeID(file: UndecoratedFile) {
  return file.fileTypeID || FILE_TYPES.EXTRA.id;
}
