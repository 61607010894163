import { mapToKey } from '@/utils/arrays';
import {
  type DecoratedSequenceStepFile,
  FILE_TYPES,
  FileTypeID,
} from '@witmetrics/api-client';

export const classes = {
  wrapper: 'relative',
  header:
    'flex justify-start items-start shrink-0 w-full absolute left-0 top-0 z-10 p-3',
};

export const INITIAL_FILE_TYPES = mapToKey(Object.values(FILE_TYPES), 'id');

export function getHiddenFileIDs(
  files: DecoratedSequenceStepFile[],
  fileTypeIDs: FileTypeID[]
) {
  return mapToKey(
    files.filter((f) => !isVisibleFile(f, fileTypeIDs)),
    'fileID'
  );
}

function isVisibleFile(
  { file }: DecoratedSequenceStepFile,
  fileTypeIDs: FileTypeID[]
) {
  return (
    (!file.fileTypeID && fileTypeIDs.includes(FILE_TYPES.EXTRA.id)) ||
    (file.fileTypeID && fileTypeIDs.includes(file.fileTypeID))
  );
}
